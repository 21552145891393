import styled from 'styled-components'

export const SvgStrokeStyles = styled.svg`
  .st0 {
    fill: none;
    stroke: ${({ color }) => color};
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .stBig {
    fill: none;
    stroke: ${({ color }) => color};
    stroke-width: 11;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: none;
  }
`

export const SvgFillStyles = styled.svg`
  .st0 {
    fill: none;
  }
  .st1 {
    fill: ${({ color }) => color};
  }
`

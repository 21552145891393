import styled from 'styled-components'
import {
  maxMedia,
  captionFontSize,
  minMedia,
  calcMaxPx,
} from '../../utils'

export const VideoStyles = styled.div`
  .video {
    width: 70vw !important;
    height: calc(70vw / 16 * 9) !important;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9vw;

    &.--with-caption {
      margin-bottom: 0;
    }

    ${minMedia.large`
      width: ${calcMaxPx(70)} !important;
      height: calc(${calcMaxPx(70)} / 16 * 9) !important;
    `}
  }

  .caption-wrapper {
    max-width: 70vw;
    margin: 0 auto;

    ${minMedia.large`
      max-width: ${calcMaxPx(70)};
    `}
  }

  .caption {
    margin-bottom: 9vw;
  }

  .caption {
    margin: 1vw 0 0 3vw;
    display: block;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    max-width: none;

    ${minMedia.large`
      margin:  ${calcMaxPx(1)} 0 0  ${calcMaxPx(3)};
    `}

    ${maxMedia.mobile`
      margin-left: 0;
    `}

    > span {
      ${captionFontSize};
      display: block;
    }
  }
`

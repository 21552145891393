import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'

export const AnimatedPage = ({ children, className }) => {
  const [show, setShow] = useState(false)
  const animation = useSpring({
    opacity: show ? 1 : 0,
    config: { mass: 1, tension: 386, friction: 35 },
  })

  useEffect(() => {
    setShow(true)
  }, [show])

  return (
    <animated.div className={className} style={animation}>
      {children}
    </animated.div>
  )
}

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { SimpleImg } from 'react-simple-img'
import styled from 'styled-components'

const Image = forwardRef(
  (
    {
      alt = '',
      className = '',
      classNameCap = '',
      classNameImg = '',
      style = null,
      src = null,
      children = null,
      imageStyles = null,
      id = '',
      placeholder = false,
      zoomImage = false,
    },
    ref
  ) => {
    return (
      <StyledImage className={className && className} $zoomImage={zoomImage} style={style}>
        {src && (
          <div className="img-zoom-wrapper">
            <SimpleImg
              src={src}
              alt={alt}
              id={id && id}
              ref={ref}
              className={classNameImg}
              style={imageStyles && imageStyles}
              placeholder={placeholder}
            />
          </div>
        )}
        {children && (
          <figcaption
            className={classNameCap}
            style={imageStyles && imageStyles}
          >
            {children}
          </figcaption>
        )}
      </StyledImage>
    )
  }
)

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  classNameCap: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.any,
}

export default Image

const StyledImage = styled.figure`
  ${({ $zoomImage }) => {
    return $zoomImage
  }}

  img {
    display: block;
  }
`

import styled from 'styled-components'
import {
  smallFontSize,
  maxMedia,
  minMedia,
  colors,
  calcMaxPx,
} from '../../utils'

export const FooterStyles = styled.footer`
  padding: 1vw 1vw 9vw;
  background-color: ${colors.darkgray};
  color: ${colors.white};
  ${maxMedia.mobile`
      padding: 3vw;
  `}
  ${minMedia.large`
    padding: ${calcMaxPx(1)};
    padding-bottom: ${calcMaxPx(9)};
  `}
  .footer-inner {
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto;
    `}
    .footer-contact {
      padding-bottom: 6vw;
      ${minMedia.large`
        padding-bottom: ${calcMaxPx(6)};
      `}
      h3 {
        text-transform: none;
      }
      a:visited,
      a {
        color: ${colors.white};
      }
    }
  }
`

export const FooterNavStyles = styled.section`
  padding-bottom: 0;
  ${smallFontSize};
  color: ${colors.white};
  font-family: 'TradeGothicWeb', sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 3vw;

  ${minMedia.large`
    column-gap: ${calcMaxPx(3)};
  `}

  row-gap: 9vw;

  ${minMedia.large`
    row-gap: ${calcMaxPx(9)};
  `}

  ul,
  li {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    margin: 0;
    padding: 0;
  }

  > .social-nav {
    width: 100%;

    ${minMedia.large`
      width: auto;
      `}

    > ul {
      > li {
        display: inline;
        &:not(:first-child) {
          margin-left: 20px;
        }
        a:visited,
        a {
          color: ${colors.white};
        }
      }
    }
  }

   .location {
    ${maxMedia.ySmall`
      width: 100%;
    `}
    > p {
      color: ${colors.white};
      font-family: 'TradeGothicWeb', sans-serif;
      ${smallFontSize};
      margin-bottom: 0;
    }
  }

  > .info-nav {
    > ul {
      > li {
        display: block;
        width: 100%;
        text-align: left;
        a:visited,
        a {
          color: ${colors.white};
          ${smallFontSize};
          display: block;
        }
      }
    }
  }
`

import { useEffect } from 'react'
let firstClientX, clientX

// Vertical scrolling does not work when you start swiping horizontally.
const preventTouch = (e) => {
  const minValue = 5 // threshold
  clientX = e.touches[0].clientX - firstClientX
  if (Math.abs(clientX) > minValue) {
    e.preventDefault()
    e.returnValue = false
    return false
  }
}

const touchStart = (e) => {
  firstClientX = e.touches[0].clientX
}

export const usePreventVerticalScroll = (refCurrent) => {
  useEffect(() => {
    if (refCurrent) {
      refCurrent.addEventListener('touchstart', touchStart, {
        passive: true,
      })
      refCurrent.addEventListener('touchmove', preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('touchstart', touchStart, {
          passive: true,
        })
        refCurrent.removeEventListener('touchmove', preventTouch, {
          passive: false,
        })
      }
    }
  }, [refCurrent])
}

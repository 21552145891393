import styled from 'styled-components'
import {
  colors,
  maxMedia,
  minMedia,
  smallFontSize,
  largeFontSize,
  calcMaxPx,
} from '../../utils';

const NewsletterModuleStyles = styled.div`
  color: ${colors.brightyellow};
  background-color: ${colors.olivegreen};

  /*
  * Theme White on Black
  * Footer
  */
  &.--white-black {
    color: ${colors.white};
    background-color: ${colors.darkgray};

    form input.submit,
    form input {
      border-color: ${colors.white};
    }

    .note,
    .message {
      color: ${colors.mediumgray};

      a {
        color: ${colors.white};
        border-color: ${colors.white};
      }
    }
  }

  /*
  * Position
  * Footer
  */
  &.--footer {
    width: 40%;
    text-transform: none;
    max-width: 400px;

    ${maxMedia.mobile`
      width: 100%;
      margin-left: 0;
    `}
    ${minMedia.large`
      width: ${calcMaxPx(35)};
    `}

    .info,
    form input,
    form input.submit {
      ${smallFontSize};
    }

    .info {
      margin-bottom: 1vw;
      width: 70%;
    }

    .note {
      margin-top: 1vw;

      ${maxMedia.mobile`
        margin-bottom: 0;
      `}
    }

    form input.submit {
      margin-top: calc(-0.2em - 1px);

      ${maxMedia.mobile`
        margin-right: 0;
      `}
    }

    form {
      ${maxMedia.mobile`
        margin-bottom: 0;
      `}
      .email {
        width: 75%;

        ${maxMedia.mobile`
          width: 80%;
        `}
      }
    }
  }

  /*
  * Position repeater
  */
  &.--repeater {
    margin-bottom: 9vw;
  }

  form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    margin-bottom: 6vw;
    ${maxMedia.mobile`
      margin-top: 0;
      `}
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto;
      margin-bottom: ${calcMaxPx(9)};
    `}
    .info {
      width: 77vw;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize};
      margin-bottom: 6vw;

      ${maxMedia.mobile`
        width: 100%;
        `}
      ${minMedia.large`
        width: ${calcMaxPx(77)};
        margin-bottom: ${calcMaxPx(6)};
      `}
    }
    .note,
    .message {
      font-family: 'TradeGothicWeb', sans-serif;
      ${smallFontSize};
      margin-top: 3vw;
      width: 280px;

      ${maxMedia.mobile`
        margin-top: 6vw;
        width: 220px;
        `}

      ${minMedia.large`
        margin-top: ${calcMaxPx(3)};
      `}

      a {
        color: inherit;
        border-bottom: 1px solid ${colors.brightyellow};

        &:hover {
          cursor: pointer;
          border-bottom: 0px solid ${colors.brightyellow};
        }
      }
    }

    .message {
      width: 100%;
      margin-top: 0;
    }
    .email {
      height: 1em;
      border-bottom: 1px solid ${colors.brightyellow};
      padding: 0.1em 0;
    }
    .email {
      width: 70vw;
      ${maxMedia.mobile`
        width: 100%;
        `}
      ${minMedia.large`
        width: ${calcMaxPx(74)};
      `}
    }
    input {
      color: inherit;
      background: none;
      background-color: inherit;
      outline: none;
      border: none;
      box-shadow: none;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize};

      margin: 0;
      padding: 0;

      &::placeholder {
        color: inherit;
        opacity: 0.6;
      }
      &.submit {
        white-space: nowrap;
        border: 1px solid ${colors.brightyellow};
        background-color: transparent;
        border-radius: 0.2em;
        padding: 0.2em 0.3em 0.1em;
        margin-top: calc(-0.2em - 1px);

        ${maxMedia.mobile`
          margin-top: 3vw;
          margin-right: 50vw;
          `}

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`

export default NewsletterModuleStyles

import { createGlobalStyle } from 'styled-components'

import sabonRegular from './fonts/sabon-regular.woff'
import sabonItalic from './fonts/sabon-italic.woff'
import sabonBold from './fonts/sabon-bold.woff'
import tradeGothicA from './fonts/trade-gothic.woff'

export default createGlobalStyle`
    /* SabonWeb */
    @font-face {
        font-family: 'SabonWeb';
        src: url(${sabonRegular}) format('woff');
        font-weight: normal;
        font-display: block;
        font-style: normal;
    }
    @font-face {
        font-family: 'SabonWeb';
        src: url(${sabonItalic}) format('woff');
        font-weight: normal;
        font-display: block;
        font-style: italic;
    }
    @font-face {
        font-family: 'SabonWeb';
        src: url(${sabonBold}) format('woff');
        font-weight: bold;
        font-display: block;
        font-style: normal;
    }
    /* TradeGothicWeb */
    @font-face {
        font-family: 'TradeGothicWeb';
        src: url(${tradeGothicA}) format('woff');
        font-weight: bold;
        font-display: block;
        font-style: normal;
    }
`

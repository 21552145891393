import styled from 'styled-components'
import {
  colors,
  largeFontSize,
  defaultImgSize,
  captionFontSize,
  maxMedia,
  minMedia,
  calcMaxPx,
} from '../../utils'

export const RepeaterStyles = styled.div`
  img {
    display: block;
  }

  .title-image {
    margin-bottom: 9vw;
    ${minMedia.large`
      margin-bottom: ${calcMaxPx(9)};
    `}

    .__image {
      ${minMedia.large`
        width: calc(100% + ${calcMaxPx(2)});
        margin-left: ${calcMaxPx(-1)};
        background-color: ${colors.brightgray};
      `}

      .--has-background & {
        background-color: transparent;
      }
      img {
        ${minMedia.large`
          display: block;
          max-width: ${calcMaxPx(100)};
          margin: 0 auto;
        `}
      }
    }

    .__text {
      ${minMedia.large`
        max-width: ${calcMaxPx(100)};
        margin-left: auto;
        margin-right: auto;
      `}
    }
  }

  .question {
    display: inline-block;
    ${captionFontSize};
    margin-bottom: 1em;
    margin-top: 2em;
  }
  .large-text {
    display: block;
    ${largeFontSize};
    font-family: 'SabonWeb', serif;
    text-align: center;
    font-weight: normal;
    text-transform: initial;

    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto;
      `}
  }
  h4.large-text {
    margin-top: 1vw;
    ${minMedia.large`
      margin-top: ${calcMaxPx(1)};
    `}
  }

  .super {
    ${captionFontSize};
    line-height: 0.5vw;
    margin-left: 0.2em;
  }
  .caption {
    margin-top: 1vw;
    margin-left: 3vw;
    display: block;
    max-width: 25vw;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;

    ${maxMedia.mobile`
      max-width: 50vw;
      margin-left: 0;
    `}
    
    ${minMedia.large`
      margin-top: ${calcMaxPx(1)};
      max-width: ${calcMaxPx(25)};
      margin-left: ${calcMaxPx(3)};
    `}

    > span {
      ${captionFontSize};
      display: block;
    }
  }

  .single-image {
    display: block;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 9vw;
    ${minMedia.large`
      margin-bottom: ${calcMaxPx(9)};
    `}

    img {
      ${defaultImgSize};
      ${maxMedia.mobile`
          max-width: 77vw;
          max-height: 94vw;
      `}
    }
  }

  a.single-image,
  a.single-image:visited {
    border: none;
    color: inherit;
  }

  .two-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 9vw;
    ${minMedia.large`
      margin-bottom: ${calcMaxPx(9)};
    `}
    a,
    a:visited {
      border: none;
      color: inherit;
    }
    ${maxMedia.mobile`
      width: 100%;
      margin-bottom: 0;
      justify-content: center;
    `}
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin-left: auto;
      margin-right: auto;
    `}
    .image {
      display: block;
      flex-basis: content;
      ${maxMedia.mobile`
        margin-bottom: 9vw;
      `}
      &:nth-child(2n) {
        padding-left: 1vw;
        ${maxMedia.mobile`
          padding-left: 0;
        `}
        ${minMedia.large`
          padding-left: ${calcMaxPx(1)};
        `}
      }
      img {
        ${defaultImgSize};
        ${maxMedia.mobile`
          margin: 0 -3vw;
        `}
        ${maxMedia.mobile`
          max-width: 77vw;
          max-height: 94vw;
        `}
      }
    }
  }
`

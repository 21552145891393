import styled from 'styled-components'
import { maxMedia, minMedia, largeFontSize, calcMaxPx, iconProportionsLarge, iconWithText, colors } from '../../utils'

export const LinkBarWrapperStyles = styled.section`
  padding: 0;
  /* breakpoint ✓ */
  ${minMedia.large`
    padding: 0;
  `}
  .link-bar {
    padding: 0 1vw;
    ${maxMedia.mobile`
      padding: 0 3vw;
      `}
    ${minMedia.large`
      padding: 0;
    `}
  }
`

export const LinkBarStyles = styled.div`
  margin-left: -2vw;
  width: 100vw;
  height: 100%;
  padding: 1vw;
  ${maxMedia.mobile`
    margin-left: -6vw;
    width: 100vw;
    padding: 3vw;
    flex-direction: column;
  `}
  ${minMedia.large`
    padding: ${calcMaxPx(1)};
    margin-left: ${calcMaxPx(-1)};
  `}

  svg {
    ${iconProportionsLarge};
    ${iconWithText};

    .st0 {
      transition: all 300ms ease;
      stroke: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.darkgray};
    }
  }

  > a {
    display: block;
    width: 100%;
    height: 16vw;
    padding: 1vw;
    box-sizing: border-box;
    transition: all 300ms ease;
    border-top: 1px solid ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.lightgray};
    color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.darkgray};

    &:hover {
      cursor: pointer;
      background-color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.brightgreen};
      border-color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.brightgreen};
      color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.background : colors.darkgray};

      svg .st0 {
        stroke: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.background : colors.darkgray}
      }
    }

    ${maxMedia.mobile`
      padding: 3vw;
    `}
    ${minMedia.large`
      padding: ${calcMaxPx(1)};
      height: ${calcMaxPx(16)};
    `}
    &.left {
      /* background-color: ${({ left }) => left}; */
    }
    &.right {
      /* background-color: ${({ right }) => right}; */
    }
    &.intern-left {
      /* background-color: ${({ left }) => left}; */
    }
    &.intern-right {
      /* background-color: ${({ right }) => right}; */
    }
    > span {
      display: block;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize};
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin: 0;
      padding: 0;
      ${minMedia.large`
        max-width: ${calcMaxPx(100)};
        margin: 0 auto;
      `}
    }
  }
`

import { css } from 'styled-components'
import { maxMedia, minMedia, calcMaxPx } from '../utils'

export const iconProportionsLarge = css`
  width: 3vw;
  height: 3vw;

  ${maxMedia.mobile`

  `}

  ${minMedia.large`
    height: ${calcMaxPx(3)};
    width: ${calcMaxPx(3)};
  `}
`


export const iconWithText = css`
  margin-right: 0.25em;
`

import React from 'react'
import { colors } from '../utils'

export const Logo = ({
  style = {},
  width = '100%',
  className = '',
  color = colors.darkgray,
  height = '100%',
  viewBox = '0 17 850 40',
  x = '0px',
  y = '0px',
}) => (
  <svg
    id="logo"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    x={x}
    y={y}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Von Bartha</title>
    <g id="v">
      <path
        fill={color}
        d="M0,1.7h14.7L41.2,58l27-56.3h14.7L48.4,72.3H34L0,1.7z"
      />
      <path
        id="o"
        fill={color}
        d="M132.3,0c31,0,42,18.2,42,37.5c0,14.6-8.6,36.5-42,36.5c-33.4,0-42-21.8-42-36.5C90.3,18.2,101.2,0,132.3,0
          z M160.5,36.8c0-17-12.1-24.9-28.2-24.9s-28.2,8-28.2,24.9c0,20,15,25.4,28.2,25.4C145.5,62.2,160.5,56.7,160.5,36.8z"
      />
      <path
        id="n"
        fill={color}
        d="M194.1,1.7h14.5l47.1,50.9V1.7H269v70.5h-12.6l-49.1-52.8v52.8h-13.2V1.7z"
      />
      <path
        id="b"
        fill={color}
        d="M326.5,1.7H369c16.6,0,27.6,4.7,27.6,18c0,8.9-4.4,12.2-8.1,14.6c4.9,2.1,10.9,8.1,10.9,17.4
          c0,13.3-10.6,20.5-27.7,20.5h-45.1V1.7z M369.9,29.6c9,0,13-2.2,13-8.1c0-6-4-8.2-13-8.2h-29.6v16.4H369.9z M370.3,60.8
          c8.7,0,15.3-1.7,15.3-10.1c0-7.4-6-9.7-15.3-9.7h-30v19.8H370.3z"
      />
      <path
        id="a"
        fill={color}
        d="M443.8,1.7h15.7l34.2,70.5h-14.5l-7.4-16.2h-40.6l-7.5,16.2H409L443.8,1.7z M466.4,44.2l-14.7-31.4
          l-14.7,31.4H466.4z"
      />
      <path
        id="r"
        fill={color}
        d="M506.7,1.7h42.4c16.7,0,28.7,3.8,28.7,21c0,13-9.3,20.2-19.7,20.8l21.6,28.7h-17l-20.5-27.7h-21.8v27.7
          h-13.8V1.7z M547,33.1c11.3,0,17-1.6,17-10c0-8.2-5.7-9.9-17-9.9h-26.6v19.9H547z"
      />
      <polygon
        id="th"
        fill={color}
        points="738.6,1.7 738.6,29.7 691.2,29.7 691.2,1.7 590.4,1.7 590.4,13.6 621.9,13.6 621.9,72.3 
          635.7,72.3 635.7,13.6 677.5,13.6 677.5,72.3 691.2,72.3 691.2,41.9 738.6,41.9 738.6,72.3 752.3,72.3 752.3,1.7 	"
      />
      <path
        id="a"
        fill={color}
        d="M800.1,1.7h15.7L850,72.3h-14.5l-7.4-16.2h-40.6L780,72.3h-14.6L800.1,1.7z M822.7,44.2l-14.7-31.4
          l-14.7,31.4H822.7z"
      />
    </g>
  </svg>
)

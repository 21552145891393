import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const ExternArrow = ({
  width = '100%',
  color = colors.darkgray,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polyline className="st0" points="29.7,5.85 69.3,5.85 69.3,45.45 " />
      <line className="st0" x1="68.3" y1="6.85" x2="31.06" y2="44.09" />
      <polyline
        className="st0"
        points="25.64,18.38 5.7,18.38 5.7,69.15 56.43,69.15 56.43,51.46 "
      />
      <rect
        x="0.5"
        y="0.5"
        transform="matrix(3.267949e-07 -1 1 3.267949e-07 -6.918387e-05 75)"
        className="st1"
        width="74"
        height="74"
      />
    </SvgStrokeStyles>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalFonts from '../styles/Fonts'
import GlobalStyle from '../styles/Global'
import { Header } from './Header'
import { Footer } from './Footer'
import { CookiesBar } from '../utils/CookiesBar'
import { CookieProvider } from '../../global-state/CookieProvider'
import NewsletterModule from '../pages/landing/NewsletterModule'

export const META_QUERY = graphql`
  query MetaQuery {
    metadata: allSite {
      nodes {
        id
        siteMetadata {
          author
          description
          title
          siteUrl
        }
      }
    }
  }
`

const Layout = ({ children }) => {
  const { metadata } = useStaticQuery(META_QUERY)
  return (
    <CookieProvider>
      <section className="layout">
        <GlobalFonts />
        <GlobalStyle />
        <Header />
        <main>
          {React.cloneElement(children, {
            ...metadata,
          })}
        </main>
        <NewsletterModule color="yellow" position="landing" />
      </section>
      <CookiesBar />
      <Footer />
    </CookieProvider>
  )
}

export default Layout

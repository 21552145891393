import styled from 'styled-components'
import {
    colors, maxMedia, minMedia, calcMaxPx, breakpoints,
    iconProportionsLarge
   } from '../../utils'

export const HeaderStyles = styled.header`
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 2000;
  transition: all 500ms ease-in-out;
  background-color: ${colors.white};
  .header-bar {

    background-color: ${colors.white};
    width: 98vw;
    margin: 1vw;
    position: relative;
    position: relative;

    ${maxMedia.mobile`
      width: 94vw;
      margin: 3vw;
    `}

    @media(min-width: ${breakpoints.large + 20}px) {
      width: 100%;
      margin: ${calcMaxPx(1)} auto;
    }
    
    .logo {
      display: block;
      margin: auto;
      width: 46vw;
      height: 4vw;

      ${minMedia.large`
        width: ${calcMaxPx(46)};
        height: ${calcMaxPx(4)};
      `}
    }

    .__wrapper {
      position: relative;
      width: 100%;

      @media(min-width: ${breakpoints.large + 20}px) {
        max-width: ${calcMaxPx(100)};
        margin: 0 auto;
      }
    }

    .__menu-button,
    .__search-button {
      position: absolute;
      left: 0;
      top: 0;

      background: transparent;
      padding: 0;
      border: none;
      outline: none;;

      ${iconProportionsLarge};

      @media(hover: hover) {
        &:hover {
          cursor: pointer;
        }
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 300ms ease;
      }

      transition: all 300ms ease 0ms, visibility 0ms ease 300ms;

      &.--visible {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);

        ${minMedia.large`
          transform: translateX(${calcMaxPx(0)});
        `}
        transition: all 300ms ease 0ms, visibility 0ms ease 0ms;
      }

      &.--hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateX(1.5vw);

        ${minMedia.large`
          transform: translateX(${calcMaxPx(1.5)});
        `}
      }


      &.--open {
        svg {
          &:nth-child(1) {
            opacity: 0;
            transform: translateX(-1.5vw);

            ${minMedia.large`
              transform: translateX(${calcMaxPx(-1.5)});
            `}
          }

          &:nth-child(2) {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      &.--close {
        svg {
          &:nth-child(1) {
            opacity: 1;
            transform: translateX(0);
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translateX(1.5vw);

            ${minMedia.large`
              transform: translateX(${calcMaxPx(1.5)});
            `}
          }
        }
      }
    }

    .__search-button {
      left: initial;
      right: 0;
    }
  }
`

import { css } from 'styled-components'

export const breakpoints = {
  xSmall: 320,
  small: 420,
  ySmall: 600,
  medium: 840,
  mobile: 1000,
  normal: 1140,
  large: 1280
}

export const calcMaxPx = (vwMax) => `${(breakpoints.large / 100) * vwMax}px`

export const mobileMediaCondition = `(max-width: ${breakpoints.mobile}px)`
export const smallMediaCondition = `(max-width: ${breakpoints.small}px)`
export const largeMediaCondition = `(min-width: ${breakpoints.large}px)`

export const minMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const maxMedia = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const InternArrow = ({
  width = '100%',
  color = colors.darkgray,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polyline className="st0" points="37.11,2.5 72.11,37.5 37.11,72.5 " />
      <line className="st0" x1="70.11" y1="37.5" x2="0" y2="37.5" />
      <rect
        x="0.5"
        y="0.5"
        transform="matrix(3.267949e-07 -1 1 3.267949e-07 -3.203718e-05 75)"
        className="st1"
        width="74"
        height="74"
      />
    </SvgStrokeStyles>
  )
}

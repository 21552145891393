import styled from 'styled-components'
import {
  maxMedia,
  captionFontSize,
  colors,
  calcMaxPx,
  minMedia,
} from '../../utils'

export const CookiesBarStyles = styled.section`
  position: fixed;
  background-color: white;
  left: 9vw; /* no breakpoint */
  right: 9vw;
  bottom: 25px;
  padding: 1vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  ${maxMedia.normal`
    display: block;
  `}
  ${maxMedia.mobile`
    padding: 3vw;
    left: 25px;
    right: 25px;
  `}
  ${minMedia.large`
    padding: ${calcMaxPx(1)};
  `}

  .cookies--visible & {
    opacity: 1;
    visibility: visible;
  }

  p,
  a,
  button {
    ${captionFontSize};
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  p {
    ${maxMedia.normal`
      display: block;
    `}
  }

  button,
  a {
    white-space: nowrap;
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-right: 1rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.darkgray};
    cursor: pointer;
  }

  .button-group {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    .decline {
      color: ${colors.brightgray};
      border-bottom-color: ${colors.brightgray};
    }
  }
`

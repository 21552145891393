// import { css } from 'styled-components'

export const colors = {
  white: `#fff`, // rgb(255, 255, 255), hsl(0, 0%, 100%)
  lightgray: `#e3e3e3`, // rgb(216, 216, 216), hsl(0, 0%, 85%)
  mediumgray: `#a0a0a0`,
  brightgray: `#d8d8d8`, // rgb(216, 216, 216), hsl(0, 0%, 85%)
  blueishgray: `#b2bfc8`, // rgb(178, 191, 200), hsl(205, 17%, 74%)
  darkgray: `#1d1d1b`, // rgb(29, 29, 27), hsl(60, 4%, 11%)
  olivegreen: `#bccaba`, // rgb(188, 202, 186), hsl(112, 13%, 76%)
  brightyellow: `#e9ff53`, // rgb(233, 255, 83), hsl(68, 100%, 66%)
  brightgreen: `#d1ffc3`, // rgb(209, 255, 195), hsl(106, 100%, 88%)
  acidblue: `#73fcee`, // rgb(209, 255, 195), hsl(106, 100%, 88%)
  red: `#ff4301`, // rgb(255, 67, 1), hsl(16, 100%, 50%)
  oysterpink: `#ecc8ca`, // rgb(255, 67, 1), hsl(16, 100%, 50%)
  lucidWhite: `rgba(255, 255, 255, 0.4)`,
  lucidBlack: `rgba(0, 0, 0, 0.2)`,
}

import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const IconSearch = ({
  width = '100%',
  color = colors.darkgray,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <circle className="stBig" cx="32.49" cy="32.44" r="26.65"/>
    <line className="stBig" x1="69.87" y1="69.9" x2="51.29" y2="51.33"/>
    </SvgStrokeStyles>
  )
}

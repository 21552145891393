import { css } from 'styled-components'
import { maxMedia, minMedia, calcMaxPx } from '../utils'

export const twoColsWrapper = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6vw 1vw;
  
  width: 100%;
  margin: 0 auto 6vw;

  ${maxMedia.mobile`
    grid-template-columns: 1fr;
  `}

  ${minMedia.large`
    width: ${calcMaxPx(100)};
    gap: ${calcMaxPx(6)} ${calcMaxPx(1)};
    margin-bottom: ${calcMaxPx(6)};
  `}
`

export const twoColsItem = css`
  display: block;
  width: 100%;

  figure {
    &:hover,
    &:focus {
      img {
        transform: scale(1.05);
      }
    }
    .img-zoom-wrapper {
      overflow: hidden;
      height: 33.3vw;
      margin-bottom: 1vw;
      ${maxMedia.mobile`
        height: 66.6vw;
      `}
      ${minMedia.large`
        height: ${calcMaxPx(33.3)};
        margin-bottom: ${calcMaxPx(1)};
      `}
      img {
        height: 100%;
        object-fit: cover;
        transition: transform 300ms ease, opacity 0.3s ease 0s !important;
      }
    }
  }
`

export const threeColsWrapper = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6vw 1vw;
  
  width: 100%;
  margin: 0 auto;

  ${maxMedia.mobile`
    grid-template-columns: 1fr;
  `}

  ${minMedia.large`
    width: ${calcMaxPx(100)};
    gap: ${calcMaxPx(6)} ${calcMaxPx(1)};
  `}
`

export const threeColsItem = css`
  display: block;
  width: 100%;

  figure {
    &:hover,
    &:focus {
      img {
        transform: scale(1.3);
      }
    }
    .img-zoom-wrapper {
      overflow: hidden;
      height: 22vw;
      margin-bottom: 1vw;
      ${maxMedia.mobile`
          height: 44vw;
        `}
      ${minMedia.large`
          height: ${calcMaxPx(22)};
          margin-bottom: ${calcMaxPx(1)};
        `}
      img {
        height: 100%;
        object-fit: cover;
        transition: transform 300ms ease, opacity 0.3s ease 0s !important;
      }
    }
  }
`

import { useEffect, useCallback } from 'react'

export const useOnClickOutside = ({
  refs,
  toggle = false,
  handler = () => {},
}) => {
  const listener = useCallback(
    (e) => {
      if (toggle) {
        if (Array.isArray(refs)) {
          refs.forEach((ref, i) => {
            const currentComponent = ref.current
            if (!currentComponent || currentComponent.contains(e.target)) {
              return
            }
            handler()
          })
        } else {
          const currentComponent = refs.current
          if (!currentComponent || currentComponent.contains(e.target)) {
            return
          }
          handler()
        }
      }
    },
    [refs, toggle, handler]
  )

  useEffect(() => {
    if (toggle) {
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }
  }, [listener, toggle])
}

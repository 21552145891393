import styled from 'styled-components'

import {
  colors,
  maxMedia,
  minMedia,
  serifFontSize,
  largeFontSize,
  calcMaxPx,
} from '../../utils'
import { defaultSliderStyles } from './SliderStyles'

export const AdvancedSliderStyles = styled.section`
  margin-left: -1vw;
  /* height: calc(100vh - 9vw); */
  ${defaultSliderStyles}
  background-color: ${colors.lightgray};
  color: ${colors.darkgray};
  width: 100vw;
  padding-top: 2vw;
  overflow: hidden;
  background-color: ${({ $backendColors }) => $backendColors.background};
  color: ${({ $backendColors }) => $backendColors.text};
  border-color: ${({ $backendColors }) => $backendColors.text};

  p a {
    color: inherit;
    border-bottom: 1px solid black;
    border-color: inherit;
  }

  margin-bottom: 0 !important;
  position: relative;
  ${minMedia.large`
    margin-left: ${calcMaxPx(-1)};
    padding-top: ${calcMaxPx(2)};
  `}
  ${maxMedia.mobile`
    padding-top: 0;
    margin-bottom: 0 !important;
    /* height: calc(100vh - 10.5vw); */
  `}
  .loading {
    ${maxMedia.mobile`
      position: absolute;
      z-index: 2;
    `}
  }

  .slick-slider {
    z-index: 1;
    margin: 0 auto !important;
    overflow: visible !important;
    .slick-list {
      overflow: visible !important;
      transition: height ease-out 400ms;
    }
    button {
      &.slick-next {
        z-index: 100;
        height: calc(100% - 6vw - 4px);
        top: calc(6vw + 4px);
      }
      &.slick-next,
      &.slick-prev {
        opacity: 0;
        ${maxMedia.mobile`
          height: calc(100% - 12vw - 4px);
          top: calc(12vw + 4px);
          display: initial !important;
        `}
      }
    }
  }

  .slide-header {
    z-index: 10000;
    position: relative;
    z-index: 10;
    .button {
      margin-right: 1vw;
      ${minMedia.large`
        margin: 0 !important;
      `}
      ${maxMedia.mobile`
        margin-right: 3vw;
        margin-top: calc(6vw + 2px);
      `}
    }
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto !important;
    `}
  }

  .slide-wrapper {
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      // margin: 0 auto !important;
      overflow: hidden !important;
    `}
  }

  ${maxMedia.mobile`
    margin-left: -3vw;
  `}
  .slide-link-text {
    /*
      .slide-link-text colors are listed here
      to omit passing backendColors-prop down to every single slide
      component if not necessary
    */
    color: ${colors.darkgray};
    border-bottom: 1px solid ${({ $backendColors }) => $backendColors.text};
    color: ${({ $backendColors }) => $backendColors.text};

    border-color: ${({ $backendColors }) => $backendColors.text};

    p a {
      color: inherit;
      border-bottom: 1px solid black;
      border-color: inherit;
    }
  }
  .slick-slide {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`

export const DefaultSlideStyles = styled.article`
  /* height: calc(100vh - 11.5vw); */
  width: 100vw;
  position: relative;
  overflow-y: hidden;
  overflow-x: visible;
  background-size: cover;
  /* z-index: -1; */
  ${maxMedia.mobile`
    background-image: url(${({ $bgImage }) => $bgImage});
    overflow: visible;
    min-height: ${({ $height }) => $height}px;
  `}
  > * {
    width: 98vw;
    ${maxMedia.mobile`
      width: 94vw;
      `}
    ${minMedia.large`
      width: ${calcMaxPx(100)};
      `}
  }

  > .slide-figure {
    position: absolute;
    left: 0;
    top: calc(-6vw - 4px);
    width: 100vw;
    z-index: 1;
    ${maxMedia.mobile`
      display: block;
      position: absolute;
      opacity: 1;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);

    `}
    .slide-image {
      height: 100%;
      object-fit: cover !important;
      object-position: ${({ $crop }) => $crop};
      z-index: 0;
      display: none;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      opacity: 1;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .slide-header {
    padding-left: 1vw;
    z-index: 10000 !important;

    ${maxMedia.mobile`
      padding-top: calc(6vw + 2px);
      padding-left: 3vw;
    `}
    ${minMedia.large`
      padding-left: 0;
    `}
    ${largeFontSize}
      font-family: 'TradeGothicWeb', sans-serif;
    > * {
      display: block;
    }
    > div {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .slide-year {
        flex-basis: 50%;
        text-align: left;
        margin: 0;
      }
    }

    h3 {
      margin-top: ${({ $isYear }) => ($isYear ? '1vw' : '0')};

      flex-basis: 100%;
      text-align: left;
      margin-left: ${({ $isYear }) => ($isYear ? '8vw' : '0')};
      width: ${({ $isYear }) => ($isYear ? 'calc(100vw - 9vw - 2vw)' : '100vw')};
      text-transform: none;

      ${maxMedia.mobile`
        margin-top: ${({ $isYear }) => ($isYear ? '3vw' : '0')};
        width: ${({ $isYear }) =>
          $isYear ? 'calc(100vw - 9vw - 6vw)' : '100vw'};
      `}
    }
  }
  .slide-body {
    position: relative;
    z-index: 10;
    /* height: calc((100vh - 14.5vw) * 5 / 9); */
    width: 100vw;
    margin-top: 5vw;

    ${minMedia.large`
      margin-top: ${calcMaxPx(5)};
    `}

    /* position: absolute;
    bottom: 0;
    left: 0; */

    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    align-content: flex-end;

    ${maxMedia.mobile`
      padding-right: 1.5vw;
      height: initial;
    `}

    .slide-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      align-content: flex-start;
      margin-bottom: 1vw;
      margin-left: 1vw;
      ${minMedia.large`
        margin: 0 auto;
        margin-bottom: ${calcMaxPx(1)}
      `}
    }

    .slide-figure {
      width: 50%;

      .slide-image {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: calc((100vh - 14.5vw) * 5 / 9);

        ${maxMedia.mobile`
          height: calc((100vh - 12.5vw) / 2);
        `}
      }
    }
    .slide-text {
      width: 50%;
      margin: 0;
      margin-left: 1vw;
      margin-right: 1vw;
      margin-bottom: 0.75vw;
      overflow: hidden;
      ${maxMedia.mobile`
        max-width: initial;
        flex-basis: 100%;
        padding: 12vw;
        padding-top: 0;
        padding-bottom: 10vw;
        `}
      ${minMedia.large`
        margin-left: ${calcMaxPx(1)};
        margin-right: ${calcMaxPx(1)};
        margin-bottom: ${calcMaxPx(0.75)};
      `}
      p {
        ${serifFontSize}
        margin: 0;
      }
    }
  }
`

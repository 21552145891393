import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { FooterStyles, FooterNavStyles } from '../styles/elements'
import { rmPTagStr } from '../../lib/stringRefs'
import { Facebook, Instagram } from '../styles/icons'

const FACEBOOK = `Facebook`
const INSTAGRAM = `Instagram`

export const FOOTER_QUERY = graphql`
  query FooterQuery {
    footer: wp {
      acfFooter {
        acfContact {
          contact {
            text: contactText
            email
            phone
          }
          locations {
            ... on WpAcfFooter_Acfcontact_Locations_Location {
              address
              locationName
            }
          }
        }
      }
    }
    infoNav: wpMenu(
      slug: { eq: "footer-info-navigation" }
    ) {
      ...NavFragment
    }
    socialNav: wpMenu(
      slug: { eq: "footer-social-navigation" }
    ) {
      ...NavFragment
    }
  }
`

export const Footer = () => {

  const {
    footer: {
      acfFooter: {
        acfContact: {
          // contact: { text = ``, email = ``, phone = `` },
          locations = [],
        }
      },
    },
    infoNav = {},
    socialNav = {},
  } = useStaticQuery(FOOTER_QUERY)

  return (
    <FooterStyles>
      <div className="footer-inner">
        <FooterNavStyles>
          <FooterSocialNav {...socialNav} />
          <FooterAdresses locations={locations} />
          <FooterInfoNav {...infoNav} />
        </FooterNavStyles>
      </div>
    </FooterStyles>
  )
}

const FooterAdresses = ({ locations }) => {
  return locations.map(({ name, address }, index) => (
    <div className={`location item-${index}`} key={index}>
      <p dangerouslySetInnerHTML={{ __html: rmPTagStr(address) }} />
    </div>
  ))
}

const FooterInfoNav = ({ items }) => {
  return (
    <nav className="info-nav">
      <ul>
        {items.nodes.length > 0 && items.nodes.map(({ title, slug, url, id }) => {
          return (
            <li key={id}>
              <Link className={slug} to={url}>
                {title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const FooterSocialNav = ({ name, slug, id, items }) => {
  return (
    <nav className="social-nav">
      <ul>
        {items.nodes.length > 0 && items.nodes.map((item) => {
          const { title, id } = item
          switch (title) {
            case FACEBOOK:
              return (
                <FooterSocialNavItem key={id} {...item}>
                  <Facebook width={30} height={30} />
                </FooterSocialNavItem>
              )
            case INSTAGRAM:
              return (
                <FooterSocialNavItem key={id} {...item}>
                  <Instagram width={30} height={30} />
                </FooterSocialNavItem>
              )
            default:
              return null
          }
        })}
      </ul>
    </nav>
  )
}

const FooterSocialNavItem = ({ children, title, url, id }) => {
  return (
    <li>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </li>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import { useSpring } from 'react-spring'
import { useMedia } from 'react-use-breakpoints'
import {
  mobileMediaCondition,
  navHeight,
  navHeightMobile,
  calcMaxPx,
  largeMediaCondition,
} from '../styles/utils'
import { Nav } from './Nav'
import { IconMenu, IconClose, IconSearch, Logo } from '../styles/icons'
import { useOnClickOutside, useMeasureWindowSize } from '../../hooks'
import { HeaderStyles } from '../styles/elements'
import { Search } from '../utils/Search'
import { vwToPx, vhToPx } from '../../lib/convertValues'
import _throttle from 'lodash.throttle'


export const Header = () => {
  const [isMenu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [isMgOutAnim, setMgOutAnim] = useState(false)
  const [isInit, setIsInit] = useState(true)
  const [isScrollTop, setScrollTop] = useState(false)
  const navRef = useRef(null)
  const navListRef = useRef(null)
  const searchRef = useRef(null)
  const burgerRef = useRef(null)
  const mgGlassRef = useRef(null)
  const isMobile = useMedia(mobileMediaCondition)
  const isLarge = useMedia(largeMediaCondition)

  // Header animation settings
  // Get debounced window width and height (listen for resizes)
  const windowWidth = useMeasureWindowSize().width
  const windowHeight = useMeasureWindowSize().height
  const defaultNavHeight = `0px`

  // Convert vw and vh values into px to animate the nav height
  // e.g. vwToPx(windowWidth, 6) converts 6vw in px depending on actual windowsize
  const searchNavHeight = isLarge
    ? `${vhToPx(windowHeight, 100) - parseInt(calcMaxPx(navHeight))}px`
    : `${vhToPx(windowHeight, 100) - vwToPx(windowWidth, navHeight)}px`
  const mobileSearchNavHeight = `${
    vhToPx(windowHeight, 100) - vwToPx(windowWidth, navHeightMobile)
  }px`
  const menuNavHeight = `${
    navListRef && navListRef.current ? navListRef.current.offsetHeight : 0
  }px`

  // Select Nav Height
  const navHeightPx = isSearch
    ? isMobile
      ? mobileSearchNavHeight
      : searchNavHeight
    : menuNavHeight

  // Animation
  const headerAnimation = useSpring({
    height: isMenu || isSearch ? navHeightPx : defaultNavHeight,
    config: { mass: 1, tension: 386, friction: 35 },
  })

  // Onclick Burger
  const onBurger = () => {
    setSearch(false)
    !isMenu ? setMenu(true) : setMenu(false)
  }

  // Onclick Magnifying Glass
  const onMgGlass = () => {
    // Set the mg-glass-animation right after "pointerdown" to prevent an automatic start
    setMgOutAnim(true)
    setMenu(false)
    !isSearch ? setSearch(true) : setSearch(false)

    /*
    * No idea about refs
    * But this works
    */
    setTimeout(async () => {
      const searchEl = document.querySelector('#Search')
      if (searchEl) searchEl.focus()
    }, 300)
  }

  // Set when the user clicks outside of navigation
  useOnClickOutside({
    refs: [navRef, navListRef, searchRef],
    toggle: isMenu,
    handler: () => {
      // setMenu(false)
      // setSearch(false)
    },
  })

  // /*
  // * Make isMenuRef Update, but not retrigger addEventListerns below again
  // * https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
  // */
  // const isMenuRef = useRef();
  // useEffect (() => {
  //   isMenuRef.current = isMenu
  // }, [isMenu])

  useEffect(() => {
      let openedByHandler = false
      /*
      * https://reactjs.org/docs/hooks-effect.html
      * https://react-hooks-cheatsheet.com/useeffect
      * https://stackoverflow.com/questions/29725828/update-style-of-a-component-onscroll-in-react-js
      * https://css-tricks.com/run-useeffect-only-once/
      */
      const handleScroll = _throttle(function () {
        if (window.innerWidth > 1000 && window.scrollY === 0) {
          openedByHandler = true
          setMenu(true)
          setScrollTop(true)
        } else {
          if (openedByHandler) {
            setMenu(false)
            setScrollTop(false)
          }
        }
      }, 100)

      handleScroll()
      setIsInit(false)
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
  }, [])

  return (
    <HeaderStyles>
      <section className="header-bar">
        <div className="__wrapper">
          <button
            ref={burgerRef}
            type="button"
            className={`${isMenu ? '--open' : '--close'} __menu-button ${isSearch ? '--hidden' : '--visible'}`}
            onMouseDown={onBurger}
            onTouchStart={onBurger}
            >
            <IconMenu />
            <IconClose />
          </button>
        <h1 className="logo">
          <Link to="/" onClick={() => setMenu(false)}>
            <Logo />
          </Link>
        </h1>
          <button
            ref={mgGlassRef}
            type="button"
            onMouseDown={onMgGlass}
            onTouchStart={onMgGlass}
            className={`${isSearch ? '--open' : '--close'} __search-button`}
            >
            <IconSearch />
            <IconClose />
          </button>
        </div>
      </section>
      <Nav
        ref={{ navRef, navListRef }}
        {...{ isMenu, isSearch, setMenu, setSearch, onBurger, headerAnimation }}
      >
        {isSearch && (
          <Search
            maxResults={20}
            keysToStart={3}
            ref={{ searchRef }}
            {...{ setMenu, setSearch }}
          />
        )}
      </Nav>
    </HeaderStyles>
  )
}

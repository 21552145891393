import styled from 'styled-components'
import { largeFontSize, minMedia, calcMaxPx, iconProportionsLarge } from '../../utils'

export const Select = styled.nav`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6vw 0;
  ${minMedia.large`
    margin: ${calcMaxPx(6)} 0;
  `}

  ul,
  li,
  button {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    ${largeFontSize};
  }
  li.select-header {
    line-height: 0;

    .__icon {
      margin-left: 1vw;
      transition: transform 300ms ease;
      ${iconProportionsLarge}

      @media(hover: hover){
        &:hover {
          cursor: pointer;
        }
      }

      ${minMedia.large`
        margin-left: ${calcMaxPx(1)};
      `}

      &.--up {
        transform: rotateX(180deg);
      }
    }
  }
  > ul > li {
    display: block;
    flex-basis: content;
    text-align: center;
  }
  button.all,
  button.select-item {
    display: inline-block;
    white-space: nowrap;
    font-family: 'TradeGothicWeb', sans-serif;
    text-transform: uppercase;
    vertical-align: top;
    ${largeFontSize};
    cursor: pointer;
    text-align: center;
  }
  button.all {
    display: inline-block;
  }
`

import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import {
  colors,
  smallFontSize,
  maxMedia,
  minMedia,
  calcMaxPx,
} from '../../utils'

const NavWrapper = forwardRef(
  ({ children, style, className, onMouseMove }, ref) => {
    return (
      <animated.nav {...{ ref, style, className, onMouseMove }}>
        {children}
      </animated.nav>
    )
  }
)

export const NavStyles = styled(NavWrapper)`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100vw;
  /* background-color: ${colors.brightgray}; */
  height: 0vh;
  transition: padding 0.5s ease;
  ${maxMedia.mobile`
    padding: 0 3vw;
    ${({ isMenu, isSearch }) =>
      (isMenu || isSearch) &&
      css`
        padding: 3vw;
        width: 94vw;
      `}
      overflow: hidden;
      height: auto;
      text-align: center;
    `}
  ul.nav-wrapper {
    width: 98vw;
    padding: 1vw;
    padding-bottom: 2vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    /* width: fit-content; */
    height: fit-content;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s, visibility 0.3s;
    ${({ isMenu }) =>
      !isMenu &&
      css`
        visibility: hidden;
        opacity: 0;
      `}
    ${maxMedia.mobile`
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
    `}
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      padding: ${calcMaxPx(2)} ${calcMaxPx(0)};
      padding-bottom: ${calcMaxPx(2)};
      margin: 0 auto;
    `}
    li.nav-item {
      display: inline-block;
      font-family: 'TradeGothicWeb', sans-serif;
      ${smallFontSize}
      margin-right: 0.8em;
      color: ${colors.darkgray};
      text-decoration: none;
      box-shadow: none;
      text-transform: uppercase;
      ${minMedia.large`
        margin-right: ${calcMaxPx(0.8)};
      `}
      a {
        pointer-events: ${({ isMenu }) => (isMenu ? 'initial' : 'none')};
        &:hover {
          border-bottom: 1px solid;
        }
        &.is-active {
          border-bottom: 1px solid;
        }
      }
      &:last-child {
        margin: 0;
      }
      

      ${maxMedia.mobile`
        width: 100%;
        display: block;
        margin: 0;
        margin-bottom: 0.5em;
      `}
    }
  }
`

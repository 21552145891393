import React from 'react'
import { colors } from '../utils'

export const Facebook = ({
  style = {},
  width = '100%',
  className = '',
  height = '100%',
  viewBox = '0 0 42 42',
  x = '0px',
  y = '0px',
}) => (
  <svg
    id="facebook"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    x={x}
    y={y}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="icon"
      fill={colors.white}
      d="M22.434,42H2.318A2.318,2.318,0,0,1,0,39.682V2.318A2.318,2.318,0,0,1,2.318,0H39.682A2.318,2.318,0,0,1,42,2.318V39.682A2.318,2.318,0,0,1,39.682,42h-10.7V25.735h5.46l.817-6.338H28.979V15.35c0-1.835.51-3.086,3.142-3.086h3.356V6.593a44.912,44.912,0,0,0-4.891-.25c-4.839,0-8.152,2.954-8.152,8.379V19.4H16.96v6.338h5.474V42Z"
    />
  </svg>
)

import { css } from 'styled-components'
import { maxMedia, minMedia, calcMaxPx } from '../utils'

// Nav Height
export const navHeight = 6
export const navHeightMobile = 17

// Fontsizes
export const fontSize = {
  small: 16, // px
  smallLh: 1.25, // em
  smallMobile: 13, // px
  smallMobileLh: 1.25, // em
  medium: 3, // vw
  mediumLh: 1, // em
  mediumMobile: 4, // vw
  mediumMobileLh: 1.35, // em
  mediumBreakPointPx: 43.2, // px
  large: 5.25, // vw
  largeLh: 1, // em
  largeMobile: 5.25, // vw
  largeMobileLh: 1, // em
  largeBreakPointPx: 72, // px
  serif: 22, // px
  serifLh: 1.35, // em
  serifMobile: 14, // px
  serifMobileLh: 1.35, // em
  caption: 16, // px,
  captionLh: 1.45, // em,
  captionMobile: 10, // px
  captionMobileLh: 1.45, // em
}

export const defaultImgSize = css`
  width: auto;
  height: auto;
  min-width: 20vw;
  max-width: 40vw;
  max-height: 40vw;

  ${minMedia.large`
    min-width: ${calcMaxPx(20)};
    max-width: ${calcMaxPx(40)};
    max-height: ${calcMaxPx(40)};
  `}
`

export const smallFontSize = css`
  font-size: ${fontSize.small}px;
  line-height: ${fontSize.smallLh};
  ${maxMedia.mobile`
    font-size: ${fontSize.smallMobile}px;
    line-height: ${fontSize.smallMobileLh};
  `}
`

export const mediumFontSize = css`
  font-size: ${fontSize.medium}vw;
  line-height: ${fontSize.mediumLh};
  ${maxMedia.mobile`
    font-size: ${fontSize.mediumMobile}vw;
    line-height: ${fontSize.mediumMobileLh};
  `}
  ${minMedia.large`
    font-size: ${calcMaxPx(fontSize.medium)};
  `}
`

export const largeFontSize = css`
  font-size: ${fontSize.large}vw;
  line-height: ${fontSize.largeLh};
  ${maxMedia.mobile`
    font-size: ${fontSize.largeMobile}vw;
    line-height: ${fontSize.largeMobileLh};
  `}
  ${minMedia.large`
    font-size: ${calcMaxPx(fontSize.large)};
  `}
`

export const serifFontSize = css`
  font-size: ${fontSize.serif}px;
  line-height: ${fontSize.serifLh};
  ${maxMedia.mobile`
    font-size: ${fontSize.serifMobile}px;
    line-height: ${fontSize.serifMobileLh};
  `}
`
export const captionFontSize = css`
  font-family: 'TradeGothicWeb', sans-serif;
  font-style: normal;
  font-size: ${fontSize.caption}px;
  line-height: ${fontSize.captionLh};
  ${maxMedia.mobile`
    font-size: ${fontSize.captionMobile}px;
    line-height: ${fontSize.captionMobileLh};
  `}
`

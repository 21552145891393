import React, { useState, useEffect } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useForm } from 'react-hook-form'
import { string, object } from 'yup'
import NewsletterModuleStyles from '../../styles/pages/landing/NewsletterModuleStyles'
import { Link } from 'gatsby'

const NewsletterModule = ({ id, position, color }) => {
  const [message, setMessage] = useState('')

  const schema = object().shape({
    email: string().email().required('Required'),
  })

  const { register, handleSubmit, formState: { errors } } = useForm({
    validationSchema: schema,
  })

  useEffect(() => {
    if (errors.email) {
      if (errors.email.type === 'required') {
        setMessage('Please enter your email')
      } else if (errors.email.type === 'email') {
        setMessage('Your Email must be a valid email')
      } else {
        setMessage(errors.email.message)
      }
    }
  }, [message, errors.email])

  const onSubmit = async (data) => {
    setMessage('Loading...')
    const result = await addToMailchimp(data.email)

    if (result.result !== 'success') {
      if (result.msg.includes('already subscribed')) {
        setMessage('You are already subscribed.')
      } else {
        setMessage('Something went wrong! Please try again.')
      }
    } else {
      setMessage('Success! Please check your inbox.')
    }
  }

  return (
    <NewsletterModuleStyles key={id} className={`${
          position === 'landing'
          ? 'full-width'
          : position === 'footer'
          ? '--footer'
          : position === 'repeater'
          ? 'full-width --repeater'
          : '' } ${
          color === 'yellow'
          ? '--yellow'
          : color === 'white-black'
          ? '--white-black'
          : ''
        }`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="info">
          To be the first to know about our activities, sign up for our newsletter below.
        </p>
        <input
          className="email"
          type="text"
          placeholder="E-Mail Address"
          autoComplete="off"
          {...register('email')}
        />
        <input className="submit" type="submit" value="Submit" />
        <p className="note">
        By sharing your email you agree to our <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        {message.length !== 0 &&
          <p className="message">{message}</p>
        }
      </form>
    </NewsletterModuleStyles>
  )
}

export default NewsletterModule

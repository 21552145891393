import { decode } from 'he'
import moment from 'moment'
import { regexHtmlTagNoBr, regexHostUrl, regexPTag } from './regex'

export const upperStr = (str) => str.toUpperCase()
export const capStr = (str) =>
  str
    .replace(/\r\n/g, '<br>')
    .replace(/<\/div>/g, '<br>')
    .replace(/<\/p>/g, '<br>')
    .replace(/<br \/>/g, '<br>')
    .replace(/<br><br>/g, '<br>')
    .replace(regexHtmlTagNoBr, '')

export const rmPTagStr = (str) => str.replace(/<\/p>/g, '<br>').replace(regexPTag, '')

export const questionStr = (str) =>
  str.replace(/<h3>/g, `<em class="question">`).replace(/<\/h3>/g, `</em>`)

export const shyStr = (str) => str.replace(/\\/g, '&shy;')

export const superStr = (str) => {
  return str.replace(/\(\((.+?)\)\)/g, `<sup class="super">$1</sup>`)
}

export const colorStr = (str) => {
  return str.split(`_`).join(``).split(`-`).join(``).toLowerCase()
}

export const getHostStr = (str) => {
  if (str.includes('http')) {
    const match = str.match(regexHostUrl)
    return match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0
      ? match[2]
      : null
  } else {
    return str
  }
}

export const dateStr = ({ from, to }) => {
  const fromYear = moment(from).format('YYYY')
  const fromDate = moment(from).format('MMM DD')
  const toYear = moment(to).format('YYYY')
  const toDate = moment(to).format('MMM DD')
  return fromYear === toYear
    ? `${fromDate} – ${toDate} ${toYear}`
    : `${fromDate} ${fromDate} – ${toDate} ${toYear}`
}

export const capitalizeStr = (str) =>
  decode(
    str
      .replace(/\\/g, '')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  )

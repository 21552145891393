import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import {
  colors,
  maxMedia,
  minMedia,
  smallFontSize,
  mediumFontSize,
  largeFontSize,
  serifFontSize,
  calcMaxPx,
} from './utils'

const GlobalStyle = createGlobalStyle`
  /* ${normalize()} */
  :focus {
    outline: ${colors.white};
  }

  a:visited,
  button:visited {
    color: black;
  }

  button {
    color: black;
  }
  
  html {
    margin: 0;
    padding: 0;
    background-color: ${colors.darkgray};
    background-color: ${colors.white};
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
  }
  body {
    overflow-x: hidden;
    width: 100vw;
    color: ${colors.darkgray};
    background: none;
  }

  .layout {
    overflow-x: hidden;
  }

  html, body, div, span, applet, object, iframe,
  h1, h3, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin: 0;
    padding: 0;
    word-break: break-word;
  }

  a:visited, a {
    color: ${colors.darkgray};
    text-decoration: none;
  }

  .layout {
    max-width: 100%;
    /* overflow-x: hidden; // important to have overflow: visible for sticky titles!! */
    padding: 0 1vw; /* no breakpoint */

    ${maxMedia.mobile`
      padding: 0 3vw;
    `}

    ${minMedia.large`
      padding: 0 ${calcMaxPx(1)};
      `}
    main {
      padding-top: 12vw;
      ${minMedia.large`
        padding-top: ${calcMaxPx(12)};
      `}
    }
    .full-width {
      width: 98vw;
      object-fit: cover;
      margin-left: -1vw; /* no breakpoint */
      padding: 1vw; /* no breakpoint */

      ${maxMedia.mobile`
        width: 94vw;
        margin-left: -3vw;
        padding: 3vw;
      `}

      ${minMedia.large`
        width: calc(100%);
        margin-left: ${calcMaxPx(-1)};
        padding: ${calcMaxPx(1)};
        `}

      img {
        max-height: 70vh;
        object-fit: contain;

        ${minMedia.large`
          max-height: ${calcMaxPx(70)};
        `}

        ${maxMedia.mobile`
          max-height: none;
        `}
      }
    }
  }

  .image-wrapper {
    width: 100vw;
    position: relative;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 66vw; */
    margin-left: -1vw;
    ${minMedia.large`
      background-color:${colors.brightgray};
      width: 100vw;
    `}
    ${maxMedia.mobile`
      margin-left: -3vw;
    `}
    img {
      display: block;
      ${minMedia.large`
        width: ${calcMaxPx(100)};
        margin: 0 auto;
      `}
    }
  }

  .detail-heading {
    /*
    * @TODO: make this a global variable 0.7em or something in vw
    */
    padding: 0 0 0.5em;
    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto;
    `}
  }

  .overview-heading {
    padding-bottom: 6vw;
    ${minMedia.large`
      padding-bottom: ${calcMaxPx(6)};
    `}
  }

  .two-grid-item {
    flex-basis: 48.5vw;
    padding-bottom: 6vw;
    ${maxMedia.mobile`
      flex-basis: initial;
      width: 100%;
      padding-bottom: 9vw;
      &:nth-child(even){
        padding-left: 0;
      }
    `}
    ${minMedia.mobile`
      &:nth-child(even){
        padding-left: 1vw;
      }
    `}
    ${minMedia.large`
      flex-basis: ${calcMaxPx(49.5)};
      padding-bottom: ${calcMaxPx(6)};
      &:nth-child(even){
        padding-left: ${calcMaxPx(1)};
      }
    `}
    figure {
      margin-bottom: 1vw;

      img {
        height: 33.3vw;
        margin-bottom: 1vw;
        object-fit: cover;
        ${maxMedia.mobile`
          height: 66.6vw;
        `}
        ${minMedia.large`
          height: ${calcMaxPx(33.3)};
          margin-bottom: ${calcMaxPx(1)};
        `}
        ${maxMedia.mobile`
          height: auto;
        `}
      }
    }
  }

  .link-blocks {
    width: 100%;
    height: 100%;
    display: flex;
    ${maxMedia.mobile`
      flex-direction: column;
    `}
    a {
      width: fill-available;
    }
    .link-block {
      ${maxMedia.mobile`
        width: 100%;
        height: 90px;
      `}
      width: fill-available;
      height: calc(5.297vw * 3);
      ${minMedia.large`
        height: calc(${calcMaxPx(5.297)} * 3);
      `}
    }
  }

  .detail-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-direction: flex-start;
    width: auto;
    padding: 50px 0;
    overflow-x: scroll;

    #slide0 {
      ${maxMedia.mobile`
        margin-left: 0;
      `}
      margin-left: 33.3vw;
    }

    .slide {
      padding-left: 40px;
      margin-left: 0;
      img {
        width: auto;
        height: 50vh;
        object-fit: contain;
      }
    }
  }

  p {
    font-family: 'SabonWeb', serif;
    ${serifFontSize};
    letter-spacing: 0;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
  }

  h2, h3, h4, span.title, span.subtitle {
    font-family: 'TradeGothicWeb', sans-serif;
    ${largeFontSize};
    text-transform: uppercase;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0px;
    padding: 0px;
  }

  .home-text {
    font-family: 'TradeGothicWeb', sans-serif;
    ${largeFontSize};
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-left: 0;
    padding: 0;
    text-transform: none;
  }

  .info-heading {
    text-transform: none;
  }

  .read-more {
    display: inline-block;
    font-family: 'TradeGothicWeb', sans-serif;
    ${mediumFontSize};
    margin-left: 0;
    border-bottom: none;
    margin-top: 4px;
    white-space: nowrap;
    ${maxMedia.mobile`
      border-bottom: 1px solid ${colors.darkgray};
      margin: 9vw;
      margin-top: 3vw;
      ${largeFontSize};
    `}
  }

  .underline {
    border-bottom: 1px solid ${colors.darkgray};
  }

  .remove-scrollbar {
    &::-webkit-scrollbar { display: none; width: 0; height: 0; }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .date-small {
    margin-bottom: 5px;
    font-family: 'TradeGothicWeb', sans-serif;
    ${smallFontSize};
    text-transform: uppercase;
    word-spacing: 5px;
    display: inline-block;
  }

  ol, select, textarea, ul {
    font-size: 1.3rem;
    margin: 25px 0;
  }

  .animate-fade-in {
    opacity: 0;
    animation: fade-in 300ms 1 forwards ease-out;
  }

  @keyframes fade-in {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1
    }
  }
`

export default GlobalStyle

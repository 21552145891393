import styled from 'styled-components'
import {
  twoColsWrapper,
  twoColsItem,
  smallFontSize,
  mediumFontSize,
  largeFontSize,
  maxMedia,
  minMedia,
  calcMaxPx,
} from '../../utils'

export const SearchStyles = styled.section`
  padding: 1vw;
  padding-bottom: 0;
  ${minMedia.large`
    padding: ${calcMaxPx(1)};
  `}
  > form {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-top: -6px;

    ${minMedia.large`
      max-width: ${calcMaxPx(100)};
      margin: 0 auto;
    `}

    input {
      display: inline-block;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      margin: 0;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize}
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      line-height: 1em;
      appearance: none;
      &.search-input {
        flex-basis: 75vw;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        ${maxMedia.mobile`
          overflow: hidden;
          flex-basis: 60vw;
          text-overflow: ellipsis;
        `}
        ${minMedia.large`
          flex-basis:  ${calcMaxPx(75)};
        `}
      }
      &.search-submit {
        cursor: pointer;
      }
    }
  }
  > section {
    width: 100%;
    height: calc(100vh - 12vw);
    display: flex;
    flex-direction: column;
    gap: 9vw;
  
    ${maxMedia.mobile`
      width: 100%;
    `}
    ${minMedia.large`
      height: calc(100vh - ${calcMaxPx((5.25 + 2) * 2)});
      gap: ${calcMaxPx(9)};
    `}
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;

    > div:last-child {
      padding-bottom: 9vw;

      ${minMedia.large`
        padding-bottom: ${calcMaxPx(9)};
      `}
    }

    h2 {
      text-align: center;
      margin-bottom: 2vw;

      ${minMedia.large`
        margin-bottom: ${calcMaxPx(2)};
      `}
    }

    ul {
      ${twoColsWrapper}
      width: 100%;
      li {
        ${twoColsItem}

        ${maxMedia.mobile`
          text-align: left;
        `}

        .type-small {
          display: block;

          margin-bottom: 5px;
          font-family: 'TradeGothicWeb', sans-serif;
          ${smallFontSize};
          text-transform: uppercase;
          word-spacing: 5px;
        }

        span.title {
          white-space: initial;
          ${mediumFontSize};
        }
      }
    }
  }
`

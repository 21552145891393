import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const Download = ({
  width = '100%',
  color = colors.darkgray,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <path className="st0" d="M67.2,25V8.1H29.3L6.8,30.6v32.3"/>
        <path className="st0" d="M67.4,49.8l-16.9,16.9-16.9-16.9"/>
        <path className="st0" d="M50.4,65.8v-31.2"/>
        <polyline className="st0" points="6.8 30.6 29.3 30.6 29.3 8.1"/>
    </SvgStrokeStyles>
  )
}

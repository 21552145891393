import React, { useState, useEffect, forwardRef } from 'react'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'

import { decode } from 'he'

import {
  Search as JsSearch,
  PrefixIndexStrategy,
  LowerCaseSanitizer,
  TfIdfSearchIndex,
  StopWordsTokenizer,
  SimpleTokenizer,
} from 'js-search'

import { shyStr } from '../../lib/stringRefs'
import Image from '../utils/Image'
import { useBodyScrollLock } from '../../hooks'
import { AnimatedPage } from '../utils/AnimatedPage'
import { SearchStyles } from '../styles/elements'

/*
*
wp: allWpSearchResults(
  filter: {
    post_name: { nin: ["do-not-delete", "test"] }
    post_type: { nin: ["page"] }
  }
) {
  data: nodes {
    id: wordpress_id
    path: pathname
    content: searchData
    title: post_title
    slug: post_name
    type: post_type
    image: featured_image {
      url: source_url
    }
  }
}
*/
export const SEARCH_QUERY = graphql`
  query SearchQuery {
    events: allWpEvent(filter: { slug: { nin: ["do-not-delete", "test"] } }) {
      data: nodes {
        id
        uri
        content
        slug
        title
        type: nodeType
        acf: acfEvent {
          image: fullwidthImage2 {
            url: sourceUrl
            title
          }
        }
      }
    }

    exhibitions: allWpExhibition(
      filter: { slug: { nin: ["do-not-delete", "test"] } }
    ) {
      data: nodes {
        id
        uri
        content
        slug
        title
        type: nodeType
        acf: acfExhibition {
          image: fullwidthImage {
            url: sourceUrl
            title
          }
        }
      }
    }

    artists: allWpArtist(filter: { slug: { nin: ["do-not-delete", "test"] } }) {
      data: nodes {
        id
        uri
        content
        slug
        title
        type: nodeType
        acf: acfArtist {
          image: fullwidthImage {
            url: sourceUrl
            title
          }
        }
      }
    }

    stories: allWpStory(filter: { slug: { nin: ["do-not-delete", "test"] } }) {
      data: nodes {
        id
        uri
        slug
        title
        type: nodeType
        acf: acfRepeater {
          content {
            ... on WpStory_Acfrepeater_Content_TitleImage {
              image {
                url: sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

export const Search = forwardRef(
  ({ setMenu, setSearch, maxResults = 20, keysToStart = 3 }, ref) => {
    const { events, exhibitions, stories, artists } =
      useStaticQuery(SEARCH_QUERY)

    const data = [
      ...events.data,
      ...exhibitions.data,
      ...stories.data,
      ...artists.data,
    ]

    const searchParams = new URLSearchParams(window.location.search);

    const rememberSearch = () => {
      searchParams.set("search", inputValue);
      setTimeout(() => window.history.replaceState(null, null, window.location.pathname + '?' + searchParams.toString()), 100)
    }

    const preValueSearch = searchParams.get('search') || ''
    
    const { searchRef } = ref
    const [isLoading, setLoading] = useState(true)
    const [searchResults, setSearchResults] = useState([])
    const [pressed, setPressed] = useState(null)
    const [content, setContent] = useState(null)
    const [start, setStart] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [inputIndex, setInputIndex] = useState(0)
    const [inputValue, setInputValue] = useState(preValueSearch)

    useBodyScrollLock()

    useEffect(() => {
      setLoading(true)
      let dataToSearch = new JsSearch(`id`)
      // defines a indexing strategy for the data -> https://github.com/bvaughn/js-search#configuring-the-index-strategy
      dataToSearch.indexStrategy = new PrefixIndexStrategy()
      // defines the sanitizer for the search
      dataToSearch.sanitizer = new LowerCaseSanitizer()
      // defines the search index -> https://github.com/bvaughn/js-search#configuring-the-search-index
      // dataToSearch.searchIndex = new UnorderedSearchIndex(`title`)
      dataToSearch.searchIndex = new TfIdfSearchIndex(`id`)
      dataToSearch.tokenizer = new StopWordsTokenizer(new SimpleTokenizer())
      dataToSearch.addIndex(`title`) // sets the index attribute for the title
      dataToSearch.addIndex(`content`) // sets the index attribute for the content data
      dataToSearch.addIndex(`type`) // sets the index attribute for the type
      dataToSearch.addDocuments(data) // adds the data to be searched
      setContent(dataToSearch)
      setLoading(false)

      return () => {
        setContent(null)
        setLoading(true)
        dataToSearch = null
      }
    }, [])

    // Set search data on change (input)
    const setSearchData = ({ target }) => {
      setPressed(false)
      target.value.length >= keysToStart && setStart(true)
      target.value.length < keysToStart && setStart(false)
      const queryResult = content && content.search(target.value)
      setInputValue(target.value)
      setSearchResults(queryResult)
      setInputIndex(0)
    }

    // Validate and set results
    const queryResults =
      !start && inputValue ? [] : searchResults.slice(0, maxResults)

    const queryResultsGroupsSorting = ['Artist', 'Story', 'Exhibition', 'Event']
    const plural = {
      'Artist': 'Artists',
      'Story': 'Stories',
      'Exhibition': 'Exhibitions',
      'Event': 'Events'
    }

    const queryResultsGroups =
      queryResults.reduce((a, v) => {
        const group = a.find(i => i.name === v.type)
        if (!group) {
          const g = {
            name: v.type,
            label: plural[v.type],
            id: v.id,
            items: [v]
          }
          a.push(g)
        } else {
          group.items.push(v)
        }
        return a
      }, []).sort((a, b) => queryResultsGroupsSorting.indexOf(a.name) - queryResultsGroupsSorting.indexOf(b.name))

    const validateTransition =
      start && queryResults && queryResults.length > 0 ? true : false

    const handleSubmit = (e) => {
      e.preventDefault()
      if (pressed && selectedItem) {
        //Fixed wrong path.
        const path = selectedItem.uri

        path && navigate(path)
        setSearch(false)
      }
      setInputIndex(0)
    }

    useEffect(() => {
      if (searchRef?.current) {
        const target = searchRef?.current?.querySelector('input')
        setSearchData({ target })
      }
    }, [isLoading])

    return (
      (isLoading && (
        <AnimatedPage>
          <h3>Loading...</h3>
        </AnimatedPage>
      )) || (
        <AnimatedPage>
          <SearchStyles ref={searchRef}>
            <form onSubmit={handleSubmit}>
              <input
                id="Search"
                className="search-input"
                value={inputValue}
                onChange={setSearchData}
                placeholder="Type here"
                autoComplete="off"
              />
              <input className="search-submit" type="submit" value="Search" />
            </form>
            <section>
              { validateTransition &&
                queryResultsGroups.map(group => {
                  return (
                    <div className="animate-fade-in" key={group.id}>
                      <h2>{group.label}</h2>
                      <ul>
                      {group.items.map((item) => {
                          const image =
                            item.acf && item.acf.image
                              ? item.acf.image
                              : item.acf &&
                                item.acf.content &&
                                item.acf.content[0].image &&
                                item.acf.content[0].image
                              ? item.acf.content[0].image
                              : null

                          return (
                            <li key={item.id}>
                              <Link
                                onClick={() => {
                                  rememberSearch()
                                  setInputValue(decode(shyStr(item.title)))
                                  setMenu(false)
                                  setSearch(false)
                                }}
                                to={item.uri}
                              >
                                {image ? (
                                  <Image src={image.url}>
                                    <span
                                      className="title"
                                      dangerouslySetInnerHTML={{
                                        __html: shyStr(item.title),
                                      }}
                                    />
                                  </Image>
                                ) : (
                                  <span
                                    className="title"
                                    dangerouslySetInnerHTML={{
                                      __html: shyStr(item.title),
                                    }}
                                  />
                                )}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })
              }

              {inputValue && queryResults.length === 0 && (
                <ul>
                  <li>No entries found.</li>
                </ul>
              )}
            </section>
          </SearchStyles>
        </AnimatedPage>
      )
    )
  }
)

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BackArrow } from '../../icons'
import {
  maxMedia,
  minMedia,
  largeFontSize,
  calcMaxPx,
  colors,
  iconProportionsLarge,
  iconWithText
} from '../../utils'

export const Back = ({
  to = `/`,
  backendColors = {
    text: null,
    background: null
  },
  children = null,
}) => {
  return (
    <StyledBackButton $backendColors={backendColors}>
      <div className="back-button">
        <Link to={to}>
          <span>
            <BackArrow width={100} height={100} color={backendColors.text} />
            {children ? children : `Back`}
          </span>
        </Link>
      </div>
    </StyledBackButton>
  )
}

const StyledBackButton = styled.div`
  .back-button {
    margin-left: -1vw;
    width: 100vw;

    ${maxMedia.mobile`
      margin-left: -6vw;
      padding: 0 3vw;
    `}
    ${minMedia.large`
      margin-left: ${calcMaxPx(-1)};
    `}

    a {
      display: block;
      width: 100%;
      height: 16vw;
      padding: 1vw;
      box-sizing: border-box;
      transition: all 300ms ease;
      border-top: 1px solid ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.lightgray};
      color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.darkgray};

      &:hover {
        cursor: pointer;
        background-color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.brightgreen};
        border-color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.brightgreen};
        color: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.background : colors.darkgray};

        svg .st0 {
          stroke: ${({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.background : colors.darkgray}
        }
      }

      ${maxMedia.mobile`
        padding: 3vw;
      `}
      ${minMedia.large`
        padding: ${calcMaxPx(1)};
        height: ${calcMaxPx(16)};
      `}
    }

    span {
      /* color: ${({ color }) => color}; */
      display: block;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize};
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin: 0;
      padding: 0;

      ${minMedia.large`
        max-width: ${calcMaxPx(100)};
        margin: 0 auto;
      `}

      svg {
        ${iconProportionsLarge};
        ${iconWithText};

        .st0 {
          transition: all 300ms ease;
          stroke: ${ ({ $backendColors }) => $backendColors && $backendColors.text ? $backendColors.text : colors.darkgray };
        }
      }
    }
  }
`

import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

export const useCookie = ({ key = '', expires = 7 }) => {
  let initial = Cookies.get(key)

  if (initial === 'undefined' || initial === undefined) initial = null

  const [cookie, setStateCookie] = useState(
    typeof initial === 'string' ? JSON.parse(initial) : initial
  )

  useEffect(() => {

    if (cookie === null) {
      document.body.classList.add('cookies--visible')
    } else {
      document.body.classList.remove('cookies--visible')
    }

    if (cookie !== true) {
      Cookies.set(key, cookie, { expires })
    } else {
      Cookies.set(key, cookie)
    }
  }, [cookie, key, expires])

  return [cookie, setStateCookie]
}

import React from 'react'
import styled, { css } from 'styled-components'
import { colors, captionFontSize, minMedia, calcMaxPx } from '../../utils'
import moment from 'moment'
import { decode } from 'he'

const TEXT = 'text'
const IMAGE = 'image'

export const Quote = ({
  name = '',
  link = '',
  date = '',
  type = '',
  author = {
    text: '',
  },
  children = null,
}) => {
  const quoteDate = moment(date).format('DD.MM.YYYY')
  const quoteYear = moment(date).format('YYYY')
  const authorText = author.text

  return name ? (
    <StyledQuote type={type}>
      {children && children}
      {` `}
      {type === IMAGE && (
        <>
          <a href={link} title={name} className="link" target="_blank" rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: name,
              }}
            />
          </a>
          {authorText && (
            <>
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: authorText,
                }}
              />
            </>
          )}
          <br />
          {date && (
            <>
              {` `}
              <span
                className="date"
                dangerouslySetInnerHTML={{
                  __html: quoteDate,
                }}
              />
            </>
          )}
        </>
      )}
      {type === TEXT && (
        <>
          <span className={TEXT}>
            {decode(`${name}${date ? `, ${quoteYear}` : ``}`)}
          </span>
        </>
      )}
    </StyledQuote>
  ) : (
    ``
  )
}

const StyledQuote = styled.div`
  ${({ type }) => {
    switch (type) {
      case TEXT:
        return textCss
      case IMAGE:
        return imageCss
      default:
        return ``
    }
  }}
`

const textCss = css`
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 3vw;
  margin-bottom: 9vw;
  ${captionFontSize};
  ${minMedia.large`
    margin-bottom: ${calcMaxPx(3)};
    margin-bottom: ${calcMaxPx(9)};
  `}
`

const imageCss = css`
  margin-top: 3vw;
  margin-bottom: 9vw;
  text-align: center;
  font-family: 'SabonWeb', serif;
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  ${minMedia.large`
    margin-top: ${calcMaxPx(3)};
    margin-bottom: ${calcMaxPx(9)};
  `}

  a {
    color: inherit;
    display: inline-block;
    white-space: nowrap;
    border-bottom: 1px solid ${colors.darkgray};
    line-height: 1.2em;
  }
  .date {
    display: inline-block;
    line-height: 2em;
  }
`

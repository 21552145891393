import React, { forwardRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { animated as a } from 'react-spring'
import { NavStyles } from '../styles/elements'

export const NAV_QUERY = graphql`
  query NavQuery {
    menu: wpMenu(slug: { eq: "header-navigation" }) {
      ...NavFragment
    }
  }
`

export const Nav = forwardRef(
  (
    { isMenu, isSearch, setMenu, setSearch, children = null, headerAnimation },
    ref
  ) => {
    const {
      menu: { items: { nodes: items } },
    } = useStaticQuery(NAV_QUERY)
    const { navRef, navListRef } = ref

    return (
      <NavStyles
        ref={navRef}
        style={headerAnimation}
        isMenu={isMenu}
        isSearch={isSearch}
        className="remove-scrollbar"
      >
        {!isSearch && (
          <a.ul ref={navListRef} className="nav-wrapper">
            {items.length > 0 && items.map(({ title, url, id }) => (
              <li className="nav-item" key={id}>
                <Link
                  to={url}
                  activeClassName='is-active'
                  onClick={() => {
                    setMenu(false)
                    setSearch(false)
                  }}
                >
                  {title}
                </Link>
              </li>
            ))}
          </a.ul>
        )}

        {isSearch && children && children}
      </NavStyles>
    )
  }
)

import { useState } from 'react'
import { useMedia } from 'react-use-breakpoints'
import { useSpring, animated as a } from 'react-spring'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import { mobileMediaCondition } from '../components/styles/utils'

export const useMouseFollow = (defaultMousePosition = [0, 0]) => {
  const mobileMedia = useMedia(mobileMediaCondition)
  const [mousePosition, setMousePosition] = useState(defaultMousePosition)
  const [mouseEnter, setMouseEnter] = useState(false)

  // Call useSpring with default config values (in %)
  const [animate, api] = useSpring(() => ({
    left: mousePosition[0],
    top: mousePosition[1],
    opacity: 0.0,
    config: { tension: 1200, friction: 70 },
  }))

  // Call useMeasure for measuring parent object
  const [ref, { left, top, width, height }] = useMeasure({
    debounce: 500,
    scroll: true,
    polyfill: ResizeObserver,
  })

  const measureMousEnter = (e) => {
    if (!mobileMedia) {
      setMousePosition([e.clientX, e.clientY])
      setMouseEnter(true)
    }
  }

  // If mobile, call useSpring api on mouseMove and calculate the % value of mouse position in parent object
  const handleMouseMove = (e) => {
    if (!mobileMedia && mouseEnter) {
      api.start({
        left: (100 / width) * (e.clientX - left),
        top: (100 / height) * (e.clientY - top),
        opacity: 1.0,
      })
    }
  }

  // Stop the mouseFollow animation process by calling the api and animate the button back to 50%
  const stopAnimation = (e) => {
    if (!mobileMedia) {
      api.start({
        opacity: 0.0,
      })
      setMouseEnter(false)
    }
  }

  // Animate left and top of the button
  const leftAnim = () => animate.left.to((left) => `${left}%`)
  const topAnim = () => animate.top.to((top) => `${top}%`)
  const opacityAnim = () => animate.opacity.to((opacity) => `${opacity}`)

  return {
    mouseFollow: {
      ref,
      a,
      left: leftAnim(),
      top: topAnim(),
      opacity: opacityAnim(),
      measureMousEnter,
      handleMouseMove,
      stopAnimation,
    },
  }
}

import styled from 'styled-components'
import { colors, minMedia, calcMaxPx } from '../../utils'

export const MgGlass = styled.button`
  position: absolute;
  right: 0;
  top: 0;

  display: block;
  height: 3vw;
  width: 3vw;

  ${minMedia.large`
    height: ${calcMaxPx(3)};
    width: ${calcMaxPx(3)};
  `}

  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;

  span {
    display: block;
    position: absolute;
  }

  span:nth-child(1) {
    top: 0;
    left: 0;

    width: calc(65% - 0.55vw);
    height: calc(65% - 0.55vw);
    border: 0.55vw solid ${colors.darkgray};

    ${minMedia.large`
      height: calc(65% - ${calcMaxPx(0.55)});
      width: calc(65% - ${calcMaxPx(0.55)});
      border-width: ${calcMaxPx(0.55)};
    `}

    background-color: transparent;
    border-radius: 50%;
    transition: opacity 0.25s ease-in-out;
  }

  span:nth-child(2) {
    top: 80%;
    left: 75%;
    width: 45%;
    height: 0.55vw;

    ${minMedia.large`
      height: ${calcMaxPx(0.55)};
    `}

    background-color: ${colors.darkgray};
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.25s ease-in-out;
  }

  span:nth-child(3) {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 0.55vw;

    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center;

    ${minMedia.large`
      height: ${calcMaxPx(0.55)};
    `}

    opacity: 0;
    background-color: ${colors.darkgray};
    transition: opacity 0.25s ease-in-out;
  }

  .mg-glass-open {
    span:nth-child(1) {
      opacity: 0;
    }
    span:nth-child(2) {
      top: 50%;
      left: 50%;
      width: 100%;
    }
    span:nth-child(3) {
      opacity: 1;
    }
  }


`

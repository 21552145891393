// import React, { useState } from 'react'
import React from 'react'
import { Link } from 'gatsby'
import { set, initialize, pageview } from 'react-ga'
import { useCookieState } from '../../global-state/CookieProvider'
import { CookiesBarStyles } from '../styles/elements'

export const CookiesBar = () => {
  const { setAnalyticsCookie } = useCookieState()

  function initializeGA() {
    initialize(`UA-161634715-1`)
    set('anonymizeIp', 1)
    pageview(window.location.pathname + window.location.search)
    window.GoogleAnalyticsIntialized = true
    setAnalyticsCookie(true)
  }

  function deactivateGA() {
    window.GoogleAnalyticsIntialized = false
    setAnalyticsCookie(false)
  }

  return (
    <CookiesBarStyles>
      <p>
        We use cookies for the best experience possible.{' '}
        <Link to="/privacy-policy">Read more</Link>
      </p>
      <div className="button-group">
        <button className="accept" onClick={initializeGA}>
          Accept
        </button>
        <button className="decline" onClick={deactivateGA}>
          Decline
        </button>
      </div>
    </CookiesBarStyles>
  )
}

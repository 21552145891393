import styled from 'styled-components'
import { colors, minMedia, calcMaxPx } from '../../utils'

export const Burger = styled.button`
  background: none;
  padding: 0;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  top: 0.25vw;

  ${minMedia.large`
    top: ${calcMaxPx(0.25)};
  `}

  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2) {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  span:nth-child(3) {
    bottom: 0%;
  }

  &.burger-open {
    span:nth-child(1) {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg) ;
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      bottom: 50%;
      transform: translate(-50%, 50%) rotate(-45deg) ;
    }
  }

  & {
    display: block;
    width: 3.5vw;
    height: 3.5vw;

    ${minMedia.large`
      width: ${calcMaxPx(3.5)};
      height: ${calcMaxPx(3.5)};
    `}

    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 0.55vw;

      ${minMedia.large`
        height: ${calcMaxPx(0.55)};
      `}
      width: 100%;
      background: ${colors.darkgray};
      opacity: 1;
      left: 50%;
      transform: translate(-50%, 0);
      transition: 0.25s ease-in-out;
    }
  }
`

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  colors,
  largeFontSize,
  mediumFontSize,
  minMedia,
  calcMaxPx,
} from '../../utils'

export const SeeAll = ({
  title = 'See all',
  to = `/`,
  fill = null,
  customStyles = ``,
  button = false,
  onClick = () => {},
}) => {
  return (
    <StyledSeeAll customStyles={customStyles} className="see-all">
      {!button ? (
        <Link onClick={onClick} to={to}>
          <span>{title}</span>
        </Link>
      ) : (
        <button onClick={onClick}>
          <span>{title}</span>
        </button>
      )}
    </StyledSeeAll>
  )
}

const StyledSeeAll = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 6vw 0 9vw 0;
  text-transform: none;
  background: none;
  border: none;
  outline: none;
  ${minMedia.large`
    padding: ${calcMaxPx(6)} 0 ${calcMaxPx(8)} 0;
  `}
  > button {
    border-radius: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin: 0;
    padding: 0;
    padding: 0 1rem;
    padding-top: 0.4vw;
    color: ${colors.white};
    border: none;
    background-color: ${colors.lucidBlack};
    cursor: pointer;
    ${minMedia.large`
      padding-top: ${calcMaxPx(0.4)};
    `}
    > span {
      white-space: nowrap;
      /* display: block; */
      width: fit-content;
      font-family: 'TradeGothicWeb', sans-serif;
      ${mediumFontSize};
      text-transform: uppercase;
    }
  }
  > a {
    > span {
      width: fit-content;
      display: block;
      font-family: 'TradeGothicWeb', sans-serif;
      ${largeFontSize};
      text-transform: uppercase;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid ${colors.darkgray};
    }
  }
  ${({ customStyles }) => customStyles}
`

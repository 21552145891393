import React from 'react'
import { colors } from '../utils'

export const Instagram = ({
  style = {},
  width = '100%',
  className = '',
  color = colors.white,
  height = '100%',
  viewBox = '0 0 42 42',
  x = '0px',
  y = '0px',
}) => (
  <svg
    id="instagram"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    x={x}
    y={y}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="icon">
      <path
        id="outer-frame"
        fill={color}
        d="M21,3.784c5.607,0,6.271.021,8.486.122a11.65,11.65,0,0,1,3.9.723A6.51,6.51,0,0,1,35.8,6.2a6.51,6.51,0,0,1,1.571,2.415,11.65,11.65,0,0,1,.723,3.9c.1,2.215.122,2.879.122,8.486s-.021,6.271-.122,8.486a11.65,11.65,0,0,1-.723,3.9,6.954,6.954,0,0,1-3.986,3.986,11.65,11.65,0,0,1-3.9.723c-2.214.1-2.878.122-8.486.122s-6.272-.021-8.486-.122a11.65,11.65,0,0,1-3.9-.723A6.51,6.51,0,0,1,6.2,35.8a6.51,6.51,0,0,1-1.571-2.415,11.65,11.65,0,0,1-.723-3.9c-.1-2.215-.122-2.879-.122-8.486s.021-6.271.122-8.486a11.65,11.65,0,0,1,.723-3.9A6.51,6.51,0,0,1,6.2,6.2,6.51,6.51,0,0,1,8.615,4.629a11.65,11.65,0,0,1,3.9-.723c2.215-.1,2.879-.122,8.486-.122M21,0c-5.7,0-6.418.024-8.658.126a15.418,15.418,0,0,0-5.1.976A10.287,10.287,0,0,0,3.525,3.525,10.287,10.287,0,0,0,1.1,7.244a15.418,15.418,0,0,0-.976,5.1C.024,14.582,0,15.3,0,21s.024,6.418.126,8.658a15.418,15.418,0,0,0,.976,5.1,10.287,10.287,0,0,0,2.423,3.719A10.287,10.287,0,0,0,7.244,40.9a15.418,15.418,0,0,0,5.1.976c2.24.1,2.955.126,8.658.126s6.418-.024,8.658-.126a15.418,15.418,0,0,0,5.1-.976A10.749,10.749,0,0,0,40.9,34.756a15.418,15.418,0,0,0,.976-5.1C41.976,27.418,42,26.7,42,21s-.024-6.418-.126-8.658a15.418,15.418,0,0,0-.976-5.1,10.287,10.287,0,0,0-2.423-3.719A10.287,10.287,0,0,0,34.756,1.1a15.418,15.418,0,0,0-5.1-.976C27.418.024,26.7,0,21,0Z"
      />
      <path
        id="inner-circle"
        fill={color}
        d="M21,10.216A10.784,10.784,0,1,0,31.784,21,10.784,10.784,0,0,0,21,10.216ZM21,28a7,7,0,1,1,7-7A7,7,0,0,1,21,28Z"
      />
      <circle id="circle" fill={color} cx="32.21" cy="9.79" r="2.52" />
    </g>
  </svg>
)

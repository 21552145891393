import React, { createContext, useContext } from 'react'
import { useCookie } from '../hooks'

export const AppContext = createContext({
  isAnalyticsCookie: false,
})

export const CookieProvider = ({ children }) => {
  const [isCookie, setCookie] = useCookie({
    key: 'gatsby-gdpr-google-analytics',
    expires: 7,
  })

  return (
    <AppContext.Provider
      value={{
        isAnalyticsCookie: isCookie,
        setAnalyticsCookie: setCookie,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useCookieState = () => useContext(AppContext)

import styled, { css } from 'styled-components'
import {
  maxMedia,
  noSelect,
  defaultImgSize,
  captionFontSize,
  mediumFontSize,
  minMedia,
  calcMaxPx,
} from '../../utils'

export const defaultSliderStyles = css`
  margin-bottom: 9vw;
  ${maxMedia.mobile`
    margin-bottom: 2rem;
  `}
  ${minMedia.large`
    margin-bottom: ${calcMaxPx(9)};
  `}
  &.slick-slider--single {
    .slick-track {
      justify-content: center;
    }
  }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    ${noSelect};
    margin: 0 -1.5vw;

    ${minMedia.large`
      max-width: ${calcMaxPx(103)};
      margin: 0 auto;
    `}

    button {
      position: absolute;
      text-transform: none;
      background: none;
      padding: 0;
      border: none;
      outline: none;
      z-index: 1000;
      height: 100%;
      width: 15%;
      top: 0;
      cursor: pointer;
      ${mediumFontSize};
      display: flex;
      align-items: center;

      ${maxMedia.mobile`
        display: none !important;
      `}

      &.slick-prev {
        left: 0;
        justify-content: start;

        svg {
          transform: rotate(180deg);
        }

        &:hover {
          span {
            transform: translateX(-0.4vw);
          }
        }
      }

      &.slick-next {
        right: 0;
        justify-content: end;

        &:hover {
          span {
            transform: translateX(0.4vw);
          }
        }
      }

      span {
        display: inline-block;
        margin: 3vw;
        padding: 0.25em 0.3em;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0.15em;
        backdrop-filter: blur(5px);
        transition: transform 200ms ease;

        ${minMedia.large`
          margin: ${calcMaxPx(3)};
        `}
      }

      svg {
        display: block;
        width: 1em;
        height: 1em;
      }
    }
    .heading {
      display: flex;
      justify-content: space-between;
    }
  }
  .slick-list {
    position: relative;
    overflow: visible;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }
  .slick-slide {
    display: block;
    float: left;
    min-height: 1px;

    [dir='rtl'] & {
      float: right;
    }
    .slider-figure {
      margin: 0 1.5vw;
      display: inline-flex !important;
      flex-direction: column;
      width: min-content !important;

      ${maxMedia.mobile`
        margin: 0 3vw;
      `}
      ${minMedia.large`
        margin: 0 ${calcMaxPx(1.5)};
      `}

      .slider-image {
        ${defaultImgSize};

        & + figcaption {
          opacity: 0;
          transition: opacity 300ms ease-in-out 300ms;
        }

        &[style*='visible'] {
          & + figcaption {
            opacity: 1;
          }
        }
      }
      .slider-caption {
        display: flex;
        flex-wrap: wrap:
        align-items: flex-start;
        align-content: flex-start;
        margin-top: 1vw;
        margin-left: 3vw;
        display: block;
        
        ${defaultImgSize};

        ${maxMedia.mobile`
          max-width: 50vw;
          margin-left: 0;
        `}

        ${minMedia.large`
          margin-top:  ${calcMaxPx(1)};
          max-width:  ${calcMaxPx(25)};
          margin-left: ${calcMaxPx(3)}
        `}

        > span {
          ${captionFontSize};
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`

export const SliderStyles = styled.div`
  ${defaultSliderStyles}
`

import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
// import { colors } from '../utils'

export const BackArrow = ({
  width = '100%',
  color,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polyline className="st0" points="37.89,72.5 2.89,37.5 37.89,2.5 " />
      <rect
        x="0.5"
        y="0.5"
        transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 75 7.105427e-15)"
        className="st1"
        width="74"
        height="74"
      />
      <line className="st0" x1="75" y1="37.5" x2="4.89" y2="37.5" />
    </SvgStrokeStyles>
  )
}

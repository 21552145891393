import React from 'react'
import styled, { css } from 'styled-components'
import { colors, maxMedia, minMedia, calcMaxPx } from '../../utils'

const ArrowWrapper = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={className}>
      <span className="left" />
      <span className="right" />
    </button>
  )
}

const rotateA = 35
const rotateB = rotateA * 2

export const ArrowUpDown = styled(ArrowWrapper)`
  &.arrow {
    display: inline-block;
    position: relative;
    height: 5vw;
    width: 2.7vw;
    margin-left: 2vw;
    border: none;
    background: none;
    padding: 0;
    white-space: nowrap;
    cursor: pointer;
    ${maxMedia.mobile`
      width: 3vw;
    `}
    ${minMedia.large`
      height: ${calcMaxPx(5)};
      width: ${calcMaxPx(2.7)};
      margin-left: ${calcMaxPx(2)};
    `}
  }

  span {
    position: absolute;
    background-color: transparent;
    width: 2.7vw;
    height: 0.3vw;
    top: 2vw;
    display: block;
    float: right;
    ${maxMedia.mobile`
      top: 3vw;
      width: 3vw;
      height: 0.4vw;
    `}
    ${minMedia.large`
      width: ${calcMaxPx(2.7)};
      height: ${calcMaxPx(0.3)};
      top: ${calcMaxPx(2)};
    `}
    &:after {
      content: '';
      width: 2.7vw;
      height: 0.3vw;
      background-color: ${colors.darkgray};
      float: right;
      display: block;
      z-index: -1;
      transition: transform 0.3s cubic-bezier(0.25, 1.5, 0.9, 0.8);
      ${maxMedia.mobile`
        width: 3vw;
        height: 0.4vw;
      `}
      ${minMedia.large`
        width: ${calcMaxPx(2.7)};
        height: ${calcMaxPx(0.3)};
      `}
    }
    &.left {
      left: -0.7vw;
      transform: rotate(${rotateA}deg);
      ${maxMedia.mobile`
        left: -1vw;
      `}
      ${minMedia.large`
        left: ${calcMaxPx(-0.7)};
      `}
    }
    &.right {
      left: 1.3vw;
      transform: rotate(${-rotateA}deg);
      ${maxMedia.mobile`
        left: 2vw;
      `}
      ${minMedia.large`
        left: ${calcMaxPx(1.3)};
      `}
    }
    ${({ toggle }) =>
      toggle &&
      css`
        &.left:after {
          transform-origin: center center;
          transform: rotate(${-rotateB}deg);
        }
        &.right:after {
          transform-origin: center center;
          transform: rotate(${rotateB}deg);
        }
      `}
  }
`

import React from 'react'
import styled from 'styled-components'
import { maxMedia, serifFontSize, minMedia, calcMaxPx } from '../../utils'

export const SerifText = ({ text = ``, className = `serif` }) => {
  return (
    <StyledSerifText
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

const StyledSerifText = styled.div`
  width: 55%;
  max-width: 820px;
  margin: 6vw auto;
  letter-spacing: 0;
  ${serifFontSize};

  ${minMedia.large`
    margin: ${calcMaxPx(6)} auto;
  `}

  ${maxMedia.mobile`
    width: calc(100% - 18vw);
  `}

  p {
    font-family: 'SabonWeb', serif;
    font-weight: 100 !important;
    ${serifFontSize};

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  a {
    color: inherit;
    font-weight: inherit;
    border-color: inherit;
  }

  ul,
  ol {
    width: 90%;
    font-family: 'SabonWeb', serif;
    font-weight: 100 !important;
    margin: 0;
    font-size: 0.7em;
    line-height: 1.4em;
    padding-left: 1.2em;

    li {
      margin-bottom: 0.5em;
    }
  }

  h4,
    h5,
    h6 {
    font-size: inherit;
  }
`

export default SerifText

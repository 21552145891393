import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const IconClose = ({
  width = '100%',
  color = colors.darkgray,
  className = '',
  height = '100%',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="arrow"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
    <line className="stBig" x1="68.13" y1="68.2" x2="5.87" y2="5.94"/>
    <line className="stBig" x1="5.87" y1="68.2" x2="68.13" y2="5.94"/>
    </SvgStrokeStyles>
  )
}

import React from 'react'
import { SvgStrokeStyles } from './SvgStyles'
import { colors } from '../utils'

export const IconMenu = ({
  width = '100%',
  height = '100%',
  color = colors.darkgray,
  className = '',
  viewBox = '0 0 75 75',
  x = '0px',
  y = '0px',
}) => {
  return (
    <SvgStrokeStyles
      id="icon-menu"
      width={width}
      height={height}
      viewBox={viewBox}
      color={color}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <line className="stBig" y1="7.5" x2="74" y2="7.5"/>
      <line className="stBig" y1="66.4" x2="74" y2="66.4"/>
      <line className="stBig" y1="36.95" x2="74" y2="36.95"/>
    </SvgStrokeStyles>
  )
}
